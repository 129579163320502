<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-btn
          color="color3 color3Text--text"
          class="mr-2"
          @click.stop="getNextMatch"
          :disabled="!!matchListString"
          :loading="loading.includes('nextMatch')"
        >Get Possible Match</v-btn>
        <v-btn
          color="error white--text"
          @click.stop="reset(true)"
        >Reset</v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="color3" text small @click="searchClick('keep')">search</v-btn>
        <v-text-field
          label="Keep Id"
          color="color3"
          v-model="keep"
          @blur="loadProfile(true)"
          solo
          clearable
          :disabled="!!matchListString"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-btn color="color3" text small @click="searchClick('drop')">search</v-btn>
        <v-text-field
          label="Drop Ids"
          color="color3"
          v-model="drop"
          @blur="loadProfile()"
          solo
          clearable
          :disabled="!!matchListString"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="cols">
        <v-expand-transition>
          <v-card v-if="keeper || loading.includes('keep')">
            <loading-bar :value="loading.includes('keep')"></loading-bar>
            <v-alert type="error" v-if="keeper && keeper.deleted">
              This profile is marked deleted.
            </v-alert>
            <v-simple-table
              dense
            >
              <template v-slot:default>
                <tbody>
                  <tr v-for="row in keepTable" :key="row.label">
                    <th>{{row.label}}</th>
                    <td>{{row.value}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-expand-transition>
        <div class="d-flex justify-space-between align-center" v-if="keeper">
          <v-switch
            label="Deleted"
            color="success"
            v-model="keeper.deleted"
            @click.stop="toggleDelete(keeper)"
            hide-details
            class="mt-0"
            :disabled="loading.includes('patch')"
            :loading="loading.includes(keeper.id)"
          ></v-switch>
          <v-btn color="color3" text x-small :to="`/player/${keeper.id}`" target="_blank">
            <v-icon x-small>fas fa-external-link</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col :cols="cols" v-for="(id, i) in dropIds" :key="id">
        <v-expand-transition>
          <v-card v-if="drops[i] || loading.includes('drop')">
            <loading-bar :value="loading.includes('drop')"></loading-bar>
            <v-alert type="error" v-if="drops[i] && drops[i].deleted">
              This profile is marked deleted.
            </v-alert>
            <v-simple-table
              dense
            >
              <template v-slot:default>
                <tbody>
                  <tr v-for="row in dropTables[id]" :key="row.label">
                    <td>
                      <v-btn
                        color="success"
                        text small
                        v-if="keeper && row.label !== 'id' && row.value && row.value !== keeper[row.label]"
                        @click.stop="patch(row, id)"
                        :disabled="loading.includes('patch')"
                        :loading="loading.includes(id)"
                      >
                        <v-icon>fas fa-arrow-alt-to-left</v-icon>
                      </v-btn>
                    </td>
                    <th>{{row.label}}</th>
                    <td :class="{'error--text': keeper && row.label !== 'id' && row.value && row.value !== keeper[row.label]}">{{row.value}}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-expand-transition>
        <div class="text-right" v-if="id && drops[i]">
          <v-switch
            label="Deleted"
            color="success"
            v-model="drops[i].deleted"
            @click.stop="toggleDelete(drops[i])"
            hide-details
            class="mt-0 shrink"
            :disabled="loading.includes('patch')"
            :loading="loading.includes(id)"
          ></v-switch>
          <v-btn color="color3" text x-small @click.stop="makePrimary(id)">
            make primary
          </v-btn>
          <v-btn color="color3" text x-small :to="`/player/${id}`" target="_blank">
            <v-icon x-small>fas fa-external-link</v-icon>
          </v-btn>
        </div>

      </v-col>
    </v-row>
    <v-row class="justify-center" v-if="keeper && drops.length">
      <v-btn
        color="color3 color3Text--text"
        @click.stop="mergeProfiles"
        :loading="loading.includes('merge')"
      >
        Merge Profiles
      </v-btn>
      <v-btn
        class="ml-2"
        color="color3 color3Text--text"
        @click.stop="swap"
        :loading="!!loading.length"
      >
        Swap Profiles
      </v-btn>
      <v-btn
        class="ml-2"
        color="color3 color3Text--text"
        @click.stop="notAMatch"
        :loading="!!loading.length"
      >
        Not A Match
      </v-btn>
    </v-row>
    <v-row class="justify-center" v-if="keeper && drops.length">
      <v-checkbox label="Auto Load Next Match When Complete" v-model="autoLoad" color="success"></v-checkbox>
    </v-row>
    <v-snackbar
      v-model="snack"
      :color="snackColor"
      vertical
    >
      {{ snackMsg }}
      <v-btn color="white success--text" @click.native="snack = false">Close</v-btn>
    </v-snackbar>
    <!-- PLAYER SEARCH DIALOG -->
    <player-edit-dialog :open="search" @player-selected="onPlayerSelected" :emitter="true"></player-edit-dialog>
  </v-container>
</template>

<script>
import { firstBy } from 'thenby'
const PlayerEditDialog = () => import('@/components/Forms/PlayerEditDialog.vue')

export default {
  data () {
    return {
      keep: null,
      keeper: null,
      drop: null,
      drops: [],
      loading: [],
      snack: null,
      search: false,
      where: null,
      matchListString: null,
      noneLikely: false,
      snackMsg: 'The profiles have been merged',
      snackColor: null,
      autoLoad: false
    }
  },
  computed: {
    dropIds () {
      return this.drop ? this.drop.split(/[,|]+/) : []
      // return this.drop ? this.drop.includes('|') ? this.drop.split('|') : this.drop.split(',') : []
    },
    cols () {
      if (this.dropIds.length > 4) return 12
      return 12 / ((this.dropIds.length || 1) + 1)
    },
    keepTable () {
      const r = []
      for (const key in this.keeper) {
        r.push({ label: key, value: this.keeper[key] })
      }
      return r
    },
    dropTables () {
      const t = {}
      for (const p of this.drops) {
        const r = []
        for (const key in p) {
          r.push({ label: key, value: p[key] })
        }
        t[p.id] = r
      }
      return t
    }
  },
  methods: {
    searchClick (where) {
      this.where = where
      this.search = true
    },
    onPlayerSelected (player) {
      if (this.where === 'keep') {
        this.keep = `${player.id}`
        this.loadProfile(true)
      } else {
        if (this.drop) {
          this.drop += `,${player.id}`
        } else {
          this.drop = `${player.id}`
        }
        this.loadProfile(false)
      }
      this.search = false
    },
    swap () {
      const a = JSON.parse(JSON.stringify(this.dropIds[0]))
      const b = JSON.parse(JSON.stringify(this.keep))
      this.keep = a
      this.loadProfile(true)

      const n = new Set(this.dropIds)
      n.delete(a)
      n.add(b)
      this.drop = [...n].join(',')
      this.loadProfile()
    },
    loadProfile (keep) {
      const ids = keep ? this.keep : this.drop
      if (ids) {
        const load = keep ? 'keep' : 'drop'
        this.loading.push(load)

        this.$VBL.player.getRaw(ids)
          .then(r => {
            if (keep) {
              this.keeper = r.data[0]
            } else {
              this.drops = r.data
            }
          })
          .catch(e => { console.log(e.response) })
          .finally(() => {
            this.loading = this.loading.filter(f => f !== load)
          })
      }
    },
    mergeProfiles () {
      if (this.keep && this.drop) {
        this.loading.push('merge')
        const qs = `?idString=${this.matchListString}`
        this.$VBL.player.merge({ keep: this.keep, drop: this.dropIds }, qs)
          .then(r => {
            this.snackColor = null
            this.snackMsg = 'The profiles have been merged'
            this.snack = true
            this.reset()
            if (this.autoLoad) {
              this.getNextMatch()
            }
          })
          .catch(e => console.log(e))
      }
    },
    reset (clicked) {
      this.$vuetify.goTo(-100)
      this.keep = null
      this.keeper = null
      this.drop = null
      this.drops = []
      this.loading = []
      this.matchListString = null
      if (clicked) this.autoLoad = false
    },
    patch (row, id) {
      if (row && this.keeper) {
        const dto = { id: this.keeper.id }
        dto[row.label] = row.value
        this.loading.push('patch')
        this.loading.push(id)
        this.$VBL.player.patch(dto, true)
          .then(r => {
            this.keeper = r.data
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.loading = this.loading.filter(f => f !== 'patch' && f !== id)
          })
      }
    },
    toggleDelete (player) {
      if (player) {
        const dto = {
          id: player.id,
          deleted: player.deleted
        }
        this.loading.push('patch')
        this.loading.push(dto.id)
        this.$VBL.player.patch(dto, true)
          .then(r => {
            this.keeper = r.data
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.loading = this.loading.filter(f => f !== 'patch' && f !== dto.id)
          })
      }
    },
    getNextMatch () {
      this.loading.push('nextMatch')
      this.$http.get('/data/likelyMatch')
        .then(r => {
          if (r.data) {
            this.loadMatches(r.data)
          } else {
            this.snackColor = 'error'
            this.snackMsg = 'We can not find any likely matches'
            this.snack = true
          }
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = this.loading.filter(f => f !== 'nextMatch')
        })
    },
    loadMatches (idList) {
      this.matchListString = idList
      const ids = idList.split('|').sort(firstBy(Number))
      this.keep = ids.shift()
      this.loadProfile(true)
      this.drop = ids.join(',')
      this.loadProfile()
      this.autoLoad = true
    },
    makePrimary (id) {
      let ids = this.matchListString.split('|').sort(firstBy(Number))
      ids = ids.filter(f => f !== id)
      this.keep = id
      this.loadProfile(true)
      this.drop = ids.join(',')
      this.loadProfile()
      this.autoLoad = true
    },
    notAMatch () {
      if (this.matchListString) {
        this.loading.push('nonMatch')
        this.$http.post(`/data/NonMatch?idString=${this.matchListString}`)
          .then(r => {
            this.reset()
            if (this.autoLoad) {
              this.getNextMatch()
            }
          })
          .catch(e => console.log(e))
          .finally(() => {
            this.loading = this.loading.filter(f => f !== 'nonMatch')
          })
      }
    }
  },
  components: {
    PlayerEditDialog
  },
  watch: {
    keep: function (v) {
      if (!v) {
        this.keeper = null
      }
    },
    drop: function (v) {
      if (!v) {
        this.drops = []
      }
    }
  }
}
</script>

<style scoped>

</style>
